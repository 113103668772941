<template>
<div>
  <div class="text-center">
        <input
          class="
            form__text-input form__text-input--plain form__text-input--search form__text-input--wide
            mx-auto
          "
          type="text"
          v-model="locationSearch"
          placeholder="Enter your address"
        />
  </div>

  <div class="searchResultsList__wrapper" v-if="searchResults.length">
    <div
      v-for="(locationResult, locationResultIndex) in searchResults"
      :key="locationResult.place_id"
      class="searchResultsList__item text-left"
    >
      <input
        type="radio"
        :id="'location_' + locationResultIndex"
        :value="locationResult.place_id"
        v-model="selectedLocId"
      />
      <label :for="'location_' + locationResultIndex"> {{ locationResult.description }}</label>
    </div>
  </div>

</div>
</template>

<script>
import { onBeforeMount, ref, watch } from 'vue'
import Cookies from 'js-cookie'

export default {
  name: 'Autocomplete App',
  setup() {
    let gMapKey = 'AIzaSyDzChQ7DGoCt5vH3HDqVtJvYjdVo1NUhok',
    locationSearch = ref(null),
    selectedLocId = ref(null),
    searchResults = ref([]),
    autocompleteService = ref(null),
    ignoreameUpdate = ref(false)

    onBeforeMount(() => {
      // eslint-disable-next-line
      if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
        this.autocompleteInit()
      } else {
        setTimeout(() => {
          mountGoogle()
        }, 1000)
      }
    })

    const mountGoogle = async () => {
      const googleMapScript = document.createElement('SCRIPT')
      googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?key=${gMapKey}&libraries=places&callback=autocompleteInit`
      )
      googleMapScript.setAttribute('defer', '')
      googleMapScript.setAttribute('async', '')
      document.head.appendChild(googleMapScript)
    }

    window.autocompleteInit = () => {
      googleIsMounted()
    }

    const googleIsMounted = async () => {
      initAutocomplete()
    }

    const initAutocomplete = () => {
      autocompleteService.value = new window.google.maps.places.AutocompleteService()
    }

    const displaySuggestions = (predictions, status) => {
      if (status != window.google.maps.places.PlacesServiceStatus.OK || !predictions) {
        searchResults.value = []
        return
      }
      searchResults.value = predictions
    }

    const storeValue = (locationID) => {
      Cookies.set('lid', locationID)
      
        var geocoder = new window.google.maps.Geocoder();
        let selectedResult = searchResults.value.find(x => x.place_id === locationID)
        let latitude = '', longitude = ''

        geocoder.geocode({ placeId: locationID }).then(({ results }) => {
          latitude = results[0].geometry.location.lat()
          longitude = results[0].geometry.location.lng()
          if (document.location.hostname === "test.hypa.co.za" || document.location.hostname === "localhost") {
            window.location.href = 'https://shop-test.hypa.co.za/hypa/connectivity/check?address=' + encodeURI(selectedResult.description) + '&lat=' + latitude + '&lng=' + longitude
          } else {
            window.location.href = 'https://shop.hypa.co.za/hypa/connectivity/check?address=' + encodeURI(selectedResult.description) + '&lat=' + latitude + '&lng=' + longitude
          }
        })
    }

    watch(
      () => locationSearch.value,
      (newValue) => {
        if (!ignoreameUpdate.value) {
          selectedLocId.value = null
          if (newValue) {
            autocompleteService.value.getPlacePredictions(
              {
                input: newValue,
                types: ['geocode'],
                componentRestrictions: { country: 'za' },
              },
              displaySuggestions
            )
          } else {
            searchResults.value = []
          }
        } else {
          ignoreameUpdate.value = false
        }
      }
    )

    watch(
      () => selectedLocId.value,
      (newValue) => {
        if (newValue) {
          // searchResults.value = []
          storeValue(newValue)
        }
      }
    )
    
    return {
      locationSearch,
      searchResults,
      autocompleteService,
      selectedLocId,
    }
  },
}
</script>
<style>
#autocomplete-app {
  width:100%;
}
</style>

<style lang="scss" scoped>
.searchResultsList {
  &__wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    max-width: 90%;
    @media (min-width: 515px) {
      max-width: 440px;
    }

    background-color: #fff;
    padding: 15px 15px 15px 10px;
    z-index: 1;
    margin: 10px auto;
    border-radius: 5px;
    box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.15);
  }
  &__item {
    max-width: 430px;
    padding: 4px 0;
    margin: 0 auto;
    label {
      font-family: montserrat, sans-serif;
      color: #001335;
      position: relative;
      cursor: pointer;
      display: block;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      line-height: 18px;
      orphans: 2;
      margin-left: 10px;
      margin-right: 10px;
      &:hover {
        font-weight: 700;
        color: #ff5000;
      }
    }
    input {
      display: none;
    }
  }
}

.form {
  &__label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
  &__text-input {
    font-family: montserrat, sans-serif;
    background: #fff;
    border: 1px solid #001335;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    line-height: 35px;
    padding:12px 15px 0 15px;
    border-radius: 6px;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #D0D0D0;
      opacity: 1; /* Firefox */
    }
    &--plain {
      line-height: 47px;
      padding:0 15px;
    }
    &--search {
      line-height: 44px;
      padding-left: 53px;
      padding-right: 53px;
      background-position: 20px center;
      background-repeat: no-repeat;
      overflow: hidden;
      text-overflow: ellipsis;
      box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.15);
      border: 1px solid #fff;
      margin-left: auto;
      margin-right: auto;
      background-image: url('./assets/images/search.svg');

      &:focus, &:focus-visible {
        border-width:1px!important;
        border-color: #8B8B8B!important;
      }
    }
    &--checkout {
      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #041333;
        opacity: 1; /* Firefox */
      }
    }
    &--wide {
      display:block;
      width: 100%;
      max-width: 90%;
      @media (min-width: 515px) {
        max-width: 440px;
      }
    }
  }
}
</style>
